import * as firebase from "firebase/app";
import { 
  getMessaging, 
  onMessage, 
  getToken 
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCLWRNSkUW18uxBc2TT_3ZXgfFyNTNz_oQ",
  authDomain: "pushnotificationmealper.firebaseapp.com",
  projectId: "pushnotificationmealper",
  storageBucket: "pushnotificationmealper.appspot.com",
  messagingSenderId: "921716021786",
  appId: "1:921716021786:web:c8eedb95d745c6ea45cfa3",
};
firebase.initializeApp(firebaseConfig);

const messaging = getMessaging();

getToken(messaging, {
  vapidKey:"BBVZoV512Lk1JaABl4ZcxhxCPoWbzw_AGEd1V-ovc-aR_r-fOd88wlvL96QIIHj2rU7zuaUW4U2trGMM0RTQCvQ",
}).then((currentToken) => {
    if (currentToken) {
      console.log(currentToken);
 
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  });

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // ...
  });

export const onMessageListener = () =>
  new Promise((resolve) => { 
    onMessage((payload) => {
      resolve(payload);
    });
  });
// Initialize Firebase
export default messaging