import  React,{useRef} from "react"
import "./App.scss";
import ReactToPrint from "react-to-print";
import { IoIosMail, IoMdHeadset } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { ImPhone } from "react-icons/im";
import { GrLinkedinOption } from "react-icons/gr";
import { GiCommercialAirplane, GiConsoleController } from "react-icons/gi";
import { FaVolleyballBall } from "react-icons/fa";
import { FcOldTimeCamera } from "react-icons/fc";
import { GoPrimitiveDot } from "react-icons/go";
import { FcPrint } from "react-icons/fc";

import messaging from "./firebase";

function App() {
  const cv = useRef();

  console.log(messaging);
  return (
    <div className="main">
    <ReactToPrint
          trigger={() => {            
            return (
              <div className="print">
                <FcPrint />
              </div>
            );
          }}
          content={() => cv.current}
        />
    <div className="container" ref={cv}>
      <div  className="cv" >
        <div className="left" >
          <div className="image">
            <img src="/pic/p.JPG" alt="" />
          </div>
          <div className="about">
            <h1> ABOUT ME </h1>
            <ul>
              <li>
                <span>Date of Birth:</span> February 19, 1997
              </li>
              <li>
                <span>Gender:</span> Female
              </li>
              <li>
                <span>Birth Place:</span> Ordubad, Nakhchivan, Azerbaijan
              </li>
            </ul>
          </div>
          <div className="contact">
            <h1> CONTACT </h1>
            <ul>
              <li>
                <span>
                  <MdLocationOn className="icon" />
                </span>
                Azerbaijan, Baku city.
              </li>
              <li>
                <span>
                  <ImPhone className="icon" />
                </span>
                +994 70 292 12 20
              </li>
              <li>
                <div>
                  <span>
                    <IoIosMail className="icon" />
                  </span>
                  aytacyusifzade.com@gmail.com
                </div>
                <div>
                  <span>
                    <IoIosMail className="icon" />
                  </span>
                  endlessness.com@gmail.com
                </div>
              </li>
              <li>
                <span>
                  <GrLinkedinOption className="icon" />
                </span>
                <a href="https://www.linkedin.com/in/aytacyusifzadə-886a5a171">
                  https://www.linkedin.com/ in/aytacyusifzadə-886a5a171
                </a>
              </li>
            </ul>
          </div>
          <div className="interest">
            <h1>INTEREST </h1>
            <ul>
              <li>
                <span>Books</span>
              </li>
              <li>
                <span>Problem Solving</span>
              </li>
              <li>
                <span>Physical</span>
              </li>
              <li>
                <span>Physical Activities</span>
              </li>
              <li>
                <span>Math</span>
              </li>
              <li>
                <span>Chess</span>
              </li>
            </ul>
          </div>
          <div className="hobies">
            <h1>Hobbies </h1>
            <ul>
              <li>
                <IoMdHeadset />
              </li>
              <li>
                <GiCommercialAirplane />
              </li>
              <li>
                <FaVolleyballBall />
              </li>
              <li>
                <GiConsoleController />
              </li>
              <li>
                <FcOldTimeCamera />
              </li>
            </ul>
          </div>
        </div>
        <div className="right">
          <div className="name">
            Aytac Yusifzadə
            <div className="line"></div>
          </div>
          <div className="career">
            <h1>Career Objectives</h1>
            <div className="text">
              My main objective is to become a part of an encouraging and stable
              company. I am looking for a team that will assist me in improving
              myself personally and professionally, as well as support me in
              obtaining the necessary skills in order to become the best in my
              field. It is my sincere hope that you will consider favorably my
              candidacy to become a part of your team.
            </div>
          </div>
          <div className="personal-info">
            <div className="education">
              <h1 className="mb-1">Education</h1>
              <div className="univercity">
                <div className="degree-name">Bachelor degree</div>
                <div className="profetion">
                  Bachelor of Science in World Economy
                </div>
                <div className="uni-name">Nakhchivan State University</div>
              </div>
              <div className="univercity">
                <div className="degree-name">Bachelor degree</div>
                <div className="profetion">
                  Bachelor of Science in World Economy
                </div>
                <div className="uni-name">Nakhchivan State University</div>
              </div>
            </div>
            <div className="tecnical">
              <h1 className="mb-1">TECHNICAL PROFICIENCIES: </h1>
              <ul>
                <li>MS Word</li>
                <li>MS Excel </li>
                <li>MS PowerPoint</li>
                <li>SPSS</li>
              </ul>
            </div>
          </div>
          <div className="exprience">
            <h1>EXPERIENCE</h1>
            <div className="job-container">
              <div className="job-title">Logic and Mathematics Teacher</div>
              <div className="job-time">
                2019 september – 2019 november | AzeriCom – Baku, Azerbaijan
              </div>
              <ul>
                <li>
                  -Teaching students based on national curriculum guidelines.{" "}
                </li>
                <li>-Planning, preparing and delivering lessons.</li>
                <li>
                  -Encouraging student to participation in different math
                  competitions with other classes.
                </li>
                <li>
                  -Preparing and distributing learning material such as notes,
                  assignments and quizzes.
                </li>
                <li>
                  -Sourcing the resources and supplies needed for lessons.
                </li>
              </ul>
            </div>
            <div className="job-container">
              <div className="job-title">Volunteer as Article Writer</div>
              <div className="job-time">
                2020 September - Up to present | Azerbaijan Science Association
                –Azerbaijan, Baku
              </div>
              <ul>
                <li>
                  -Writing, editing and proofreading text for publishing.{" "}
                </li>
                <li>
                  -Confer with management to discuss changes or revisions to
                  written material.
                </li>
                <li>
                  -Rewise written material to meet personal standarts and to
                  reflect and interpret needs of readers.
                </li>
              </ul>
            </div>
          </div>
          <div className="languange">
            <h1>Language SKILLS </h1>
            <ul>
              <li>
                <h3>Azerbaijani </h3>
                <h3>Native</h3>
              </li>
              <li>
                <h3>English </h3>
                <h3>Intermediate</h3>
              </li>
              <li>
                <h3>Turkish </h3>
                <h3>Advanced</h3>
              </li>
            
            </ul>
          </div>
          <div className="skills">
            <h1>Skills</h1>
            <ul>
              <li>
                <div>Attentive listening and effective oral communication skills</div>
                <div><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dotgray"/></div>
              </li>
              <li>
                <div>Professionalism and strong work ethic</div>
                <div><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dotgray"/></div>
              </li>
              <li>
                <div>Leadership Skills</div>
                <div><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dotgray"/><GoPrimitiveDot className="dotgray"/></div>
              </li>
              <li>
                <div>Critical Thinking and problem solving</div>
                <div><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dotgray"/></div>
              </li>
              <li>
                <div>Teamwork and collaboration.</div>
                <div><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dot"/><GoPrimitiveDot className="dotgray"/><GoPrimitiveDot className="dotgray"/></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default App;
